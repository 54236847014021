import { render, staticRenderFns } from "./IncreasedChannel.vue?vue&type=template&id=718e8f2b&scoped=true&"
import script from "./IncreasedChannel.vue?vue&type=script&lang=js&"
export * from "./IncreasedChannel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718e8f2b",
  null
  
)

export default component.exports
<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div style="margin-left: auto; display: flex">
        <div class="search-container-fn-input"
             v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button size="mini" type="primary" icon="el-icon-edit-outline"
                     style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
                     @click="handleEditTableHead">编辑表头
          </el-button>
        </div>

        <div class="search-container-fn-input"
             v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button size="mini" type="primary" icon="el-icon-refresh"
                     style="padding: 6px 7px;
                              background-color: #2362FB; border-color: #2362FB"
                     @click="handleResetSearch">重置查找
          </el-button>
        </div>

        <div class="search-container-fn-input"
             v-if="operateList.indexOf('update') > -1"
        >
          <el-button
              size="mini"
              type="primary"
              style="padding: 6px 7px 7px 8px;background-color: #2362FB; border-color: #2362FB"
              @click="handleEdit"
          >
            <i class="el-icon-edit" style="font-size: 13px"/>
            <span icon="el-icon-edit" style="margin-left: 5px; font-size: 12px"
            >编辑</span
            >
          </el-button>
        </div>

        <div class="search-container-fn-input"
             v-if="operateList.indexOf('delete') > -1"
        >
          <el-button
              size="mini"
              type="danger"
              style="padding: 6px 7px 7px 8px"
              @click="handleDelete"
          >
            <i class="el-icon-delete" style="font-size: 13px"/>
            <span icon="el-icon-delete" style="margin-left: 5px; font-size: 12px"
            >删除</span
            >
          </el-button>
        </div>

        <div class="search-container-fn-input"
             v-if="operateList.indexOf('add') > -1"
        >
          <el-button
              size="mini"
              type="primary"
              style="padding: 6px 7px 7px 8px;background-color: #2362FB; border-color: #2362FB"
              @click="handleAdd"
          >
            <i class="el-icon-plus" style="font-size: 13px"/>
            <span icon="el-icon-plus" style="margin-left: 5px; font-size: 12px"
            >新增</span
            >
          </el-button>
        </div>
      </div>

    </div>
    <div class="gg-container__body">

      <el-table
          border
          size="mini"
          v-loading="listLoading"
          :header-cell-style="{'text-align':'center'}"
          :data="tableData"
          height="625"
          style="width: 100%;z-index:0"
          highlight-current-row
          @selection-change="handleSelectionChange"
      >
        <el-table-column
            type="index"
            align="center"
            width="50">
        </el-table-column>
        <el-table-column type="selection" width="55" align="center"/>

        <template
            v-for="(column,index) in tableHead"
        >
          <el-table-column
              :prop="column.column_prop"
              :label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
              :width="column.width ? column.width : '' "
              :key="index"
              align="center"
              v-if="column.field_type === 'textBtn' && column.visible === true "
              show-overflow-tooltip
          >
            <!--<template #header>
              <el-popover placement="bottom" title="" width="230" trigger="hover">
                <div slot="reference" class="search-header">
                  <span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
                  <i style="margin-left: 5px" class="search-icon el-icon-search"></i>
                </div>
                <el-input
                    size="mini"
                    placeholder=""
                    prefix-icon="el-icon-search"
                    v-model="searchParams.buy_count"
                    clearable
                    @change="getList('restPage')"
                  >
                  </el-input>
              </el-popover>
            </template>-->
            <template slot-scope="scope">
              <a style="font-size: 12px; color: #2379fb"
                 @click.prevent="handleCustomerDetail(scope.row)">
                {{ scope.row[column.column_prop] }}
              </a>
            </template>
          </el-table-column>

          <el-table-column
              :prop="column.column_prop"
              :label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
              :width="column.width ? column.width : '' "
              :key="index"
              align="center"
              v-else-if="column.field_type === 'select' && column.visible === true "
              show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-switch
                  v-if="'status' == column.column_prop"
                  class="switch"
                  @change="changeExamine(scope.row)"
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="启用"
                  inactive-text="禁用"
                  active-color="#13ce66"
                  inactive-color="#C8C8C8"
              >
              </el-switch>


              <el-switch
                  v-if="'show_mobile_status' == column.column_prop"
                  class="switch"
                  @change="changeExamineShowMobileStatus(scope.row)"
                  v-model="scope.row.show_mobile_status"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="启用"
                  inactive-text="禁用"
                  active-color="#13ce66"
                  inactive-color="#C8C8C8"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column
              :prop="column.column_prop"
              :label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
              :width="column.width ? column.width : '' "
              :key="index"
              align="center"
              v-else-if="column.field_type === 'imageBtn' && column.visible === true "
              show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="scope.row[column.column_prop].length>0" style="font-size: 12px; color: #2379fb"
                   @click="handleShowImage(scope.row[column.column_prop],  scope.row[column.column_prop][0])">
                <a>点击查看图片</a>
                <el-image
                    style="width: 0px; height: 0px"
                    :ref="scope.row[column.column_prop][0]"
                    :src="imageUrl"
                    :preview-src-list="scope.row[column.column_prop]"
                    :z-index="9999">
                </el-image>
              </div>
              <div v-else>
                暂无
              </div>
            </template>
          </el-table-column>
          <el-table-column
              :sortable="tableHeadSortable.includes(column.column_prop)"
              :prop="column.column_prop"
              :label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
              :width="column.width ? column.width : '' "
              :key="index"
              align="center"
              v-else-if="column.visible === true"
              show-overflow-tooltip
          >
            <template #header>
              <el-popover placement="bottom" title="" min-width="160" trigger="click"
                          v-if="searchTableHead.filter( item => item.name == column.column_prop).length > 0">
							<span slot="reference" class="search-header">
								<span
                    class="search-title"
                >{{ column.column_label_user_definition ? column.column_label_user_definition : column.column_label }}</span>
								<i style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                   class="el-icon-search"
                   :class="{isSearch: searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch,
									  'el-icon-zoom-in': searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch}"
                />
							</span>
                <el-input
                    v-if="'order_count' == column.column_prop"
                    size="mini"
                    placeholder=""
                    prefix-icon="el-icon-search"
                    v-model="searchParams.buy_count"
                    clearable
                    @change="handleSearch(column.column_prop ,searchParams.buy_count)"
                >
                </el-input>

                <el-input
                    v-if="'mobile' == column.column_prop"
                    size="mini"
                    placeholder=""
                    prefix-icon="el-icon-search"
                    v-model="searchParams.mobile"
                    clearable
                    @change="handleSearch(column.column_prop ,searchParams.mobile)"
                >
                </el-input>


                <el-select
                    v-else-if="'agent_name' == column.column_prop"
                    style="width:200px"
                    v-model="searchParams.agent_id"
                    placeholder="请选择"
                    size="mini"
                    clearable
                    @change="handleSearch(column.column_prop ,searchParams.agent_id)"
                >
                  <template slot="prefix">
                    <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
                  </template>
                  <el-option
                      v-for="item in merchantList"
                      :key="item.value"
                      :label="item.merchant_name"
                      :value="item.id">
                  </el-option>
                </el-select>


                <el-select
                    v-else-if="'visit_level' == column.column_prop"
                    style="width:200px"
                    v-model="searchParams.visit_level"
                    placeholder="请选择"
                    size="mini"
                    clearable
                    @change="handleSearch(column.column_prop ,searchParams.visit_level)"
                >
                  <template slot="prefix">
                    <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
                  </template>
                  <el-option
                      v-for="item in optionsVisitLevel"
                      :key="item.short_name"
                      :label="item.short_name"
                      :value="item.short_name">
                  </el-option>
                </el-select>


                <el-select
                    v-else-if="'from' == column.column_prop"
                    style="width:200px"
                    v-model="searchParams.from_id"
                    placeholder="请输入客户来源"
                    size="small"
                    clearable
                    @change="handleSearch(column.column_prop ,searchParams.from_id)"
                >
                  <template slot="prefix">
                    <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
                  </template>
                  <el-option
                      v-for="item in customerFromOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>

                <el-date-picker
                    v-else-if="'created_at' == column.column_prop"
                    v-model="choiceDateCreateRecord"
                    :default-time="['00:00:00', '23:59:59']"
                    type="daterange"
                    size="mini"
                    value-format="yyyy-MM-dd HH-mm-ss "
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="handleCreateRecordChangeDate()">
                </el-date-picker>


                <el-select
                    v-else-if="'staff_name' == column.column_prop"
                    style="width:200px"
                    v-model="searchParams.staff_name"
                    placeholder="请选择"
                    size="mini"
                    clearable
                    @change="handleSearch(column.column_prop ,searchParams.staff_name)"
                >
                  <template slot="prefix">
                    <i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
                  </template>
                  <el-option
                      v-for="item in staffOptions"
                      :key="item.id"
                      :label="item.staff_name"
                      :value="item.id">
                  </el-option>
                </el-select>


              </el-popover>
              <span
                  v-else>{{ column.column_label_user_definition ? column.column_label_user_definition : column.column_label }}</span>
            </template>
            <template slot-scope="scope">
              <span v-if="column.column_prop === 'buy_status'">{{
                  scope.row[column.column_prop] === 1 ? '未购' : '已购'
                }}</span>
              <span v-else-if="column.column_prop === 'use_status'">
								{{ scope.row[column.column_prop] | useStatusFormat }}
							</span>
              <span v-else-if="column.column_prop === 'is_wx'">
								{{ scope.row[column.column_prop] == '0' ? '否' : '是' }}
							</span>
              <span v-else-if="column.column_prop === 'supervise_status'">
								{{ scope.row[column.column_prop] | formatSuperviseStatus }}
							</span>
              <span v-else>{{ scope.row[column.column_prop] }}</span>
            </template>
          </el-table-column>
        </template>

        <!--<el-table-column label="操作" align="center">-->
        <!--	<template slot-scope="scope">-->
        <!--		<el-button size="mini" type="primary" icon="el-icon-edit"-->
        <!--							 style="padding: 6px 7px; margin-right: 10px;-->
        <!--                      background-color: #2362FB; border-color: #2362FB"-->
        <!--							 @click="handleEdit(scope.row)">编辑-->
        <!--		</el-button>-->
        <!--		<el-button size="mini" type="danger" icon="el-icon-delete"-->
        <!--							 style="padding: 6px 7px; "-->
        <!--							 @click="handleDelete(scope.row)">删除-->
        <!--		</el-button>-->
        <!--	-->
        <!--	</template>-->
        <!--</el-table-column>-->
      </el-table>
      <!-- <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      /> -->

      <IncreasedChannel v-show="AddFlag" ref="IncreasedChannel"/>
      <ChangeChannels v-show="CompileFlag" ref="ChangeChannels"/>
    </div>

    <!--编辑表头-->
    <editTableHead :isActiveEditTableHead.sync="isActiveEditTableHead"
                   @_getAdminFieldIndex="_getAdminFieldIndex"
                   v-if="isActiveEditTableHead"
                   :table_type="table_type"
    >

    </editTableHead>

  </div>
</template>

<script>
import {deleteBasicConfig, putStatus, getBasicConfig, getThrowCountList} from "@/api/rank/tags";
import IncreasedChannel from "./components/IncreasedChannel";
import ChangeChannels from "./components/ChangeChannels";

import editTableHead from '@/components/editTableHead';
import {mapGetters, mapState} from 'vuex';
import {getAdminFieldIndex} from '@/api/ggkq';
// import pagination from "@/components/Pagination";
export default {
  name: "CDMSourceChannel",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ChangeChannels,
    // eslint-disable-next-line vue/no-unused-components
    IncreasedChannel,
    // pagination,
    editTableHead
  },
  computed: {
    ...mapGetters(["agentId", "adminId"]),
    ...mapState({
      is_admin: state => state.user.is_admin,
      operates: state => state.user.operates,
      table_options: state => state.user.table_options,
    }),
  },
  data() {
    return {
      table_type: 'OnTheSource',
      searchTableHead: [

        {
          name: 'recovery_count',
          isSearch: false
        },
      ],
      tableHeadSortable: ['created_at'],
      isActiveEditTableHead: false,
      tableHead: [],
      choiceDateCreateRecord: [],

      listLoading: true,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      searchParams: {
        timeValue: [],
      },
      total: 0,
      CompileFlag: false,
      AddFlag: false,
      search: "",
      tableData: [],
      operateList: [],
      multipleSelection: [],
    };
  },
  async mounted() {
    console.log(this.$route.path)
    console.log(this.operates)
    this.operates.forEach(operate => {
      if (operate[this.$route.path]) {
        console.log(operate[this.$route.path].split(','))
        this.operateList.push(...operate[this.$route.path].split(','))
      }
    })
    await this._getAdminFieldIndex()
    this.getList();
    this.$refs.ChangeChannels.$on('getList', this.getList)
    this.$refs.IncreasedChannel.$on('getList', this.getList)
  },
  methods: {
    handleSearch(prop, value) {
      this.searchTableHead.forEach(item => {
        if (item.name == prop) {
          if (value == '') {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
    },
    handleResetSearch() {
      this.searchParams = {
        type: 'pre_stop',
        is_wx: '',
        visit_level: '',
        buy_count: '',
      }
      this.searchTableHead.forEach(item => {
        item.isSearch = false
      })
      this.choiceDateCreateRecord = []
      this.getList('restPage')
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true
    },
    async _getAdminFieldIndex() {
      //   try {
      //     this.listLoading = true
      //     var params = {
      //       type: 'OnTheSource'
      //     }
      //     const res = await getAdminFieldIndex(params)
      this.tableHead = this.table_options.OnTheSource
      //     console.log(this.tableHead)
      //   } catch (err) {
      //     //在此处理错误
      //   } finally {
      //     this.listLoading = false
      //   }

    },

    //获取级别列表
    getList() {
      this.listLoading = true;
      getBasicConfig({c_type: "channel"}).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          this.listLoading = false;
        }
      });
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      val.forEach((item) => {
        this.multipleSelection.push(item);
      });
    },
    handleEdit() {
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: "error",
          message: "只能单个编辑!",
        });
        return;
      } else if (this.multipleSelection.length == 0) {
        this.$message({
          type: "error",
          message: "请选择!",
        });
        return;
      }
      this.CompileFlag = true;
      this.$refs.ChangeChannels.CompileFlag = this.CompileFlag;
      this.$refs.ChangeChannels.listData = this.multipleSelection[0];
    },
    changeExamine(event) {
      this.switchDis = true;
      console.log(event);
      let data = {
        status: Number(event.status),
      };
      putStatus(event.id, data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getList();
        } else {
          this.$message({
            message: "修改失败",
            type: "error",
          });
        }
      });
    },
    handleDelete() {
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: "error",
          message: "只能单个删除!",
        });
        return;
      } else if (this.multipleSelection.length == 0) {
        this.$message({
          type: "error",
          message: "请选择!",
        });
        return;
      }
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            deleteBasicConfig(this.multipleSelection[0].id).then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    handleAdd() {
      console.log("添加");
      this.AddFlag = true;
      this.$refs.IncreasedChannel.AddFlag = this.AddFlag;
    },
    changeSearch() {
      console.log(this.search);
      //判断搜索是否与tableData的某一项的name有相同的字符,如果有则返回true,没有则返回false
      this.tableData.forEach((item) => {
        if (item.name.indexOf(this.search) != -1) {
          this.loading = true;
          //过滤掉不符合条件的数据
          this.tableData = this.tableData.filter((item) => {
            return item.name.indexOf(this.search) != -1;
          });
          this.loading = false;
        }
        if (this.search == "") {
          this.loading = true;
          this.getList();
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.gg-container__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  .gg-container__header__title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-input {
      width: 200px;
      //   height: 30px !important;
    }
  }
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
